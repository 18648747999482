/**
 * external libs
 */
import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import './../../utils/react-scroller/styles.scss'
import Scroller, { IScrollerRef } from './../../utils/react-scroller'

/**
 * components
 */
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Home from '../slides/home/Home'
import History from '../slides/history/History'
import Products from '../slides/products/Products'
import Production from '../slides/production/Production'
import Cocktails from '../slides/cocktails/Cocktails'
import Contacts from '../slides/contacts/Contacts'
/**
 * types
 */
// import { CocktailType } from '../../types'
/**
 * styles
 */
import styles from './horizontal.module.scss'
import { GlobalContentContext } from '../../App'

type CycleType = {
    left: null | number
    right: null | number
    scroll: null | number
}

// export const COCKTAILS: CocktailType[][] = [
//     [
//         {
//             id: '1',
//             principal: 'SCHMIDT SUPREME',
//             title: 'Schmidt dry martini',
//             describe:
//                 "The highest quality ingredients, their delicate balance and perfect serving are the three components of this legendary cocktail, which in many ways has become an indicator of the bartender's skill",
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Supreme',
//                     volume: '60',
//                 },
//                 {
//                     id: '2',
//                     name: 'Dry vermouth',
//                     volume: '20',
//                 },
//             ],
//             recipe: 'Cool thoroughly and combine vermouth and vodka in a mixing glass, strain into a cocktail glass and top with an olive on a skewer',
//             image: '/cocktail1.webp',
//         },
//         {
//             id: '2',
//             principal: 'SCHMIDT SUPREME',
//             title: 'SCHMIDT WHITE RUSSIAN',
//             describe:
//                 'Schmidt White Russian is ideal premium classic. It has a creamy taste, the softness of which is emphasized by Schmidt vodka',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Supreme',
//                     volume: '40',
//                 },
//                 {
//                     id: '2',
//                     name: 'Vanilla cream',
//                     volume: '40',
//                 },
//                 {
//                     id: '3',
//                     name: 'Coffee liqueur',
//                     volume: '40',
//                 },
//             ],
//             recipe: 'Pour coffee liqueur and vodka into a rocks glass, stir and add cream. serving everything with a slice of chocolate for the perfect garnish',
//             image: '/cocktail2.webp',
//         },
//         {
//             id: '3',
//             principal: 'SCHMIDT SUPREME',
//             title: 'SCHMIDT GRASSHOPPER',
//             describe:
//                 'Schmidt Grasshopper is a creamy, smooth, digestive cocktail with a pleasant, refreshing minty aftertaste. The ideal accompaniment for it would be bitter and rich chocolate',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Supreme',
//                     volume: '30',
//                 },
//                 {
//                     id: '2',
//                     name: 'Cream',
//                     volume: '30',
//                 },
//                 {
//                     id: '3',
//                     name: 'Mint liqueur',
//                     volume: '30',
//                 },
//             ],
//             recipe: 'Decorate the rim of a cocktail or sparkling glass with melted milk chocolate. After this, mix all the ingredients in a shaker with plenty of ice and carefully filter into a glass',
//             image: '/cocktail3.webp',
//         },
//     ],
//     [
//         {
//             id: '4',
//             principal: 'SCHMIDT GRAPEFRUIT',
//             title: 'SCHMIDT PALOMA',
//             describe:
//                 'One of the most charismatic and atmospheric drinks in the whole world. A cocktail that will certainly create a bright mood',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Grapefruit',
//                     volume: '40',
//                 },
//                 {
//                     id: '2',
//                     name: 'Campari',
//                     volume: '20',
//                 },
//                 {
//                     id: '3',
//                     name: 'Grapefruit juice',
//                     volume: '80',
//                 },
//                 {
//                     id: '4',
//                     name: 'Lime juice',
//                     volume: '20',
//                 },
//                 {
//                     id: '5',
//                     name: 'Garnish',
//                     volume: 'paprika',
//                 },
//             ],
//             recipe: 'Mix Schmidt Grapefruit, fresh lime and grapefruit juices in a shaker with ice, add a little orange bitter and a pinch of salt, pour into a tall glass with ice and add a splash of soda. The edge of the glass can be decorated with salted or smoked paprika',
//             image: '/cocktail4.webp',
//         },
//         {
//             id: '5',
//             principal: 'SCHMIDT GRAPEFRUIT',
//             title: 'SCHMIDT SMASH',
//             describe:
//                 'Always fresh, always summer and always modern. Scmidt Smash is an easy to make cocktail that is perfect for dinner or a party. Fresh basil, grapefruit vodka, juicy lemon and vanilla combine perfectly in an easy and popular mix',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Grapefruit',
//                     volume: '40',
//                 },
//                 {
//                     id: '2',
//                     name: 'Lemon juice',
//                     volume: '20',
//                 },
//                 {
//                     id: '3',
//                     name: 'Vanilla syrup',
//                     volume: '10',
//                 },
//                 {
//                     id: '4',
//                     name: 'Basil',
//                     volume: '12 medium leaves',
//                 },
//                 {
//                     id: '5',
//                     name: 'egg white',
//                     volume: '10',
//                 },
//                 {
//                     id: '6',
//                     name: 'Decoration',
//                     volume: 'grapefruit',
//                 },
//                 {
//                     id: '7',
//                     name: '',
//                     volume: 'ICE',
//                 },
//             ],
//             recipe: 'Remove 12 fresh green basil leaves from the stem, place them in a shaker and add the remaining ingredients and ice. Shake everything well, then filter into a glass with ice and garnish with a slice of grapefruit.',
//             image: '/cocktail5.webp',
//         },
//     ],
//     [
//         {
//             id: '6',
//             principal: 'SCHMIDT CRANBERRY',
//             title: 'SCHMIDT VODKA TONIC',
//             describe: 'Schmidt Vodka Tonic is an impeccable mix with a clean, bittersweet, refreshing finish',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Cranberry',
//                     volume: '40',
//                 },
//                 {
//                     id: '2',
//                     name: 'Tonic',
//                     volume: '120',
//                 },
//                 {
//                     id: '3',
//                     name: 'Decoration',
//                     volume: 'currant sprig',
//                 },
//                 {
//                     id: '4',
//                     name: '',
//                     volume: 'ICE',
//                 },
//             ],
//             recipe: 'Pour vodka and tonic into a tall glass with ice. Stir and garnish with a sprig of bright currants, frozen cranberries or a spike',
//             image: '/cocktail6.webp',
//         },
//         {
//             id: '7',
//             principal: 'SCHMIDT CRANBERRY',
//             title: 'SCHMIDT CLUB',
//             describe:
//                 'A twist on the famous Clover Club cocktail. We prepare it with Schmidt Cranberry base and add the juice of thawed cranberries. The airy and light texture of this drink is perfect for the bright and juicy berry flavor of cranberries',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Cranberry',
//                     volume: '40',
//                 },
//                 {
//                     id: '2',
//                     name: 'Cranberry juice',
//                     volume: '40',
//                 },
//                 {
//                     id: '3',
//                     name: 'Sugar syrup',
//                     volume: '10',
//                 },
//                 {
//                     id: '4',
//                     name: 'Egg White',
//                     volume: '10',
//                 },
//                 {
//                     id: '5',
//                     name: 'Decoration',
//                     volume: 'mint',
//                 },
//             ],
//             recipe: 'Thaw and squeeze cranberries, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.',
//             image: '/cocktail7.webp',
//         },
//     ],
//     [
//         {
//             id: '8',
//             principal: 'SCHMIDT Black Currant',
//             title: 'SCHMIDT BLACK CURRANT MOJITO',
//             describe:
//                 'A variation of the famous refreshing cocktail, which harmoniously combines bright citrus notes, minty freshness and the sweetness of ripe currants',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Black Currant',
//                     volume: '40',
//                 },
//                 {
//                     id: '2',
//                     name: 'Lime Juice',
//                     volume: '10',
//                 },
//                 {
//                     id: '3',
//                     name: 'mint',
//                     volume: '8 leaves',
//                 },
//                 {
//                     id: '4',
//                     name: 'Lime',
//                     volume: '4 wedges',
//                 },
//                 {
//                     id: '5',
//                     name: 'Blackcurrant jam',
//                     volume: '1 teaspoon',
//                 },
//                 {
//                     id: '6',
//                     name: 'soda',
//                     volume: '120',
//                 },
//             ],
//             recipe: 'Invigorate the mint with an intense clap of your hands. Place mint, lime wedges and ice in a tall glass. Pour in the remaining ingredients and mix thoroughly. Garnish the drink with a sprig of mint and enjoy!',
//             image: '/cocktail8.webp',
//         },
//         {
//             id: '9',
//             principal: 'SCHMIDT Black Currant',
//             title: 'SCHMIDT BLACK CLUB',
//             describe:
//                 'A juicy mix based on soft berry vodka, lime juice and honey syrup. The airy foam from thawed blackcurrant juice gives the drink a special delicacy, creating a light and so drinkable texture of the cocktail.',
//             ingredients: [
//                 {
//                     id: '1',
//                     name: 'Schmidt Black Currant',
//                     volume: '40',
//                 },
//                 {
//                     id: '2',
//                     name: 'Lime Juice',
//                     volume: '10',
//                 },
//                 {
//                     id: '3',
//                     name: 'honey syrup',
//                     volume: '10',
//                 },
//                 {
//                     id: '4',
//                     name: 'Thawed blackcurrant juice',
//                     volume: '30',
//                 },
//                 {
//                     id: '5',
//                     name: 'egg white',
//                     volume: '10',
//                 },
//             ],
//             recipe: 'Thaw and squeeze blackcurrants, add this juice and the rest of the ingredients to a shaker, mix vigorously and then filter into a cocktail glass. Garnish the drink with mint or sprinkle with cinnamon.',
//             image: '/cocktail9.webp',
//         },
//     ],
// ]

// export const SLIDES = [
//     'home',
//     'brand history',
//     'products',
//     'production',
//     ...COCKTAILS.map((cocktail) => cocktail[0].principal),
//     'contacts',
// ]

export const SLIDES = ['home', 'brand history', 'products', 'production', 'cocktails', 'contacts']
const Horizontal: React.FC<{ isStart: boolean }> = memo(({ isStart }) => {
    const [slide, setSlide] = useState(0)
    const containerRef = useRef<IScrollerRef>(null)
    const slideRefs = useRef<HTMLDivElement[]>([])
    const scrollLock = useRef(false)
    const scrollPosition = useRef<number>(0)

    const { homeContent } = useContext(GlobalContentContext)
    const cocktails = homeContent?.data?.cocktail_groups

    const scrollHandler = () => {
        const scrollLeft = containerRef.current?.scrollRef?.current?.scrollLeft || 0
        const screenWidth = window.innerWidth
        const direction = scrollLeft > scrollPosition.current ? 'right' : 'left'
        let cycle: CycleType = { left: null, right: null, scroll: null }

        const active = (slideRefs.current || []).reduce((result: null | number, item, idx) => {
            if (direction === 'right') {
                if (result === null && item.getBoundingClientRect().x > screenWidth / 2) {
                    result = idx - 1
                }
            } else {
                if (
                    result === null &&
                    item.getBoundingClientRect().x + item.getBoundingClientRect().width > screenWidth / 2
                ) {
                    result = idx
                }
            }

            return result
        }, null)

        setSlide(active ?? SLIDES.length - 1)

        const mover = () => {
            if (cycle.right) {
                containerRef.current?.scrollTo(cycle.right)
            } else if (cycle.left || cycle.left === 0) {
                containerRef.current?.scrollTo(cycle.left)
            }
        }

        ;[
            slideRefs.current[2],
            ...cocktails?.map((cocktailSlide: any, idx: number) => slideRefs.current[4 + idx]),
            slideRefs.current[cocktails?.length + 4],
        ].forEach((slide, index) => {
            if (!scrollLock.current && !document.querySelector('html')?.classList.contains('noEvent')) {
                const slideRect = slide.getBoundingClientRect()
                const slideStart = slide.offsetLeft
                const slideEnd = slideStart + slide.offsetWidth
                if (slideRect.left <= screenWidth * 0.9 && slideRect.right >= screenWidth * 0.1) {
                    if (direction === 'right' && slideRect.left > screenWidth * 0.1) {
                        cycle.right = slideStart
                    } else if (direction === 'left' && slideRect.right < screenWidth * 0.9) {
                        cycle.left = slideEnd - screenWidth
                    }
                }
            }
        })

        if ((cycle.right || cycle.left) && !scrollLock.current) {
            document.querySelector('html')?.classList.add('noEvent')

            mover()

            setTimeout(() => {
                document.querySelector('html')?.classList.remove('noEvent')
            }, 300)
        }

        scrollPosition.current = scrollLeft
    }

    useEffect(() => {
        //@ts-ignore
        if (!window.customScroll) {
            //@ts-ignore
            window.customScroll = true
            containerRef.current?.scrollRef?.current?.addEventListener('scroll', scrollHandler, { passive: false })
        }

        return () => {
            containerRef.current?.scrollRef?.current?.removeEventListener('scroll', scrollHandler)
            //@ts-ignore
            window.customScroll = null
        }
    }, [])

    return (
        <Scroller ref={containerRef} horizontal={true} scrollLock={scrollLock} className={styles.horizontal}>
            {/* @ts-ignore */}
            <Header slide={slide} slideRefs={slideRefs} scrollLock={scrollLock} />

            <div className={styles.horizontal__slide} ref={(el) => (slideRefs.current[0] = el as HTMLDivElement)}>
                <Home
                    containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>}
                    isStart={isStart}
                />
            </div>

            <div className={styles.horizontal__slide} ref={(el) => (slideRefs.current[1] = el as HTMLDivElement)}>
                <History containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>} />
            </div>

            <div className={styles.horizontal__slide} ref={(el) => (slideRefs.current[2] = el as HTMLDivElement)}>
                <Products
                    isLeave={slide !== 2}
                    containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>}
                />
            </div>

            <div className={styles.horizontal__slide} ref={(el) => (slideRefs.current[3] = el as HTMLDivElement)}>
                <Production containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>} />
            </div>

            {cocktails?.map((cocktailSlide: any, idx: number) => (
                <div
                    key={cocktailSlide?.id}
                    className={styles.horizontal__slide}
                    ref={(el) => (slideRefs.current[4 + idx] = el as HTMLDivElement)}>
                    <Cocktails
                        containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>}
                        list={cocktailSlide?.cocktails}
                        principal={cocktailSlide?.title}
                    />
                </div>
            ))}

            <div
                className={styles.horizontal__slide}
                ref={(el) => (slideRefs.current[4 + cocktails?.length] = el as HTMLDivElement)}>
                <Contacts />
            </div>

            <Footer isSlide={true} slide={slide} />
        </Scroller>
    )
})

export default Horizontal
