/**
 * external libs
 */
import React, { useState, useEffect, useContext, memo } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { MoveParallax, Left } from '../../animate'
import { GoldText, H4, CustomLink } from '../../ui'
/**
 * types
 */
import { Product } from '../../../types'
/**
 * styles
 */
import styles from './products.module.scss'
import { GlobalContentContext } from '../../../App'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
    isLeave: boolean
}

// const bottles: Product[] = [
//     {
//         id: '1',
//         img: '/product1.webp',
//         color: '#F5F3EE',
//         title: 'supreme',
//         advantages: ['only alcohol and water', 'no sugar or additives', 'mild taste thanks to pearl filtration'],
//         description:
//             "Schmidt Supreme is our main classic line of premium vodka with a clean and smooth taste, which has won customers' trust in the world's key vodka markets",
//         volumes: [0.5, 0.7, 1],
//         aroma: ['clean'],
//         taste: ['soft', 'drinkable'],
//         flavorMap: ['delicate'],
//         ABV: '40%',
//         bigImg: '/bigImg1.webp',
//         bigImgBg: '/bigImgBg1.webp',
//         bigImgExtra: '/bigImgExtra1.webp',
//     },
//     {
//         id: '2',
//         img: '/product2.webp',
//         color: '#FC9B391A',
//         title: 'GRAPEFRUIT',
//         advantages: ['no sugar', 'mild taste thanks to pearl filtration', 'universal base for cocktails'],
//         description: 'Schmidt Grapefruit is a premium vodka with grapefruit flavor without added sugar',
//         volumes: [0.5, 0.7],
//         aroma: ['fresh', 'juicy'],
//         taste: ['soft', 'natural'],
//         flavorMap: ['natural sweetness', 'citrus freshness'],
//         ABV: '40%',
//         bigImg: '/bigImg2.webp',
//         bigImgBg: '/bigImgBg2.webp',
//         bigImgExtra: '/bigImgExtra2.webp',
//     },
//     {
//         id: '3',
//         img: '/product3.webp',
//         color: '#E95B4C1A',
//         title: 'CRANBERRY',
//         advantages: ['no sugar', 'mild taste thanks to pearl filtration', 'universal base for cocktails'],
//         description:
//             'Schmidt Cranberry is a uniquely pure cranberry vodka with no added sugar. The natural cranberry extract does not overwhelm the flavor notes, but complements the overall taste composition with its smoothness',
//         volumes: [0.5, 0.7],
//         aroma: ['fresh', 'berry'],
//         taste: ['soft', 'natural'],
//         flavorMap: ['tangy', 'dry', 'berry'],
//         ABV: '40%',
//         bigImg: '/bigImg3.webp',
//         bigImgBg: '/bigImgBg3.webp',
//         bigImgExtra: '/bigImgExtra3.webp',
//     },
//     {
//         id: '4',
//         img: '/product4.webp',
//         color: '#9D79CC1A',
//         title: 'BLACK CURRANT',
//         advantages: ['no sugar', 'mild taste thanks to pearl filtration', 'universal base for cocktails'],
//         description:
//             'Schmidt Black Currant is a special vodka with one of the most popular flavors - black currant. This is a soft, creamy palette of delicate flavors of sweet ripe berries and fresh aromatic currant leaves',
//         volumes: [0.7, 0.5],
//         aroma: ['fresh', 'juicy'],
//         taste: ['soft', 'natural'],
//         flavorMap: ['ripe', 'sweet berry'],
//         ABV: '40%',
//         bigImg: '/bigImg4.webp',
//         bigImgBg: '/bigImgBg4.webp',
//         bigImgExtra: '/bigImgExtra4.webp',
//     },
// ]

const Products: React.FC<Props> = memo(({ containerRef, isLeave }) => {
    const { t } = useTranslation()
    const [activePoint, setActivePoint] = useState<any | null>(null)
    const [minWidth, setMinWidth] = useState(0)
    const [hoverBottle, setHoverBottle] = useState<string | null | number>(null)

    const { homeContent } = useContext(GlobalContentContext)
    const bottles = homeContent?.data?.bottles
    bottles?.map((bottle: any) => {
        if (typeof bottle.benefits === 'string') {
            bottle.benefits = bottle?.benefits?.split('\n')
        }
    })

    useEffect(() => {
        if (isLeave) {
            setActivePoint(null)
        }
    }, [isLeave])
    useEffect(() => {
        const minWidthChanger = () => {
            const w = window.innerWidth
            const h = window.innerHeight
            const min = h < 500 ? (h < 400 ? 900 : 1100) : 1550
            setMinWidth(w < min ? min : w)
        }

        window.addEventListener('resize', minWidthChanger)
        minWidthChanger()

        return () => {
            window.removeEventListener('resize', minWidthChanger)
        }
    }, [setMinWidth])

    return (
        <div className={styles.products} style={!!activePoint && !!minWidth ? { minWidth: `${minWidth}px` } : {}}>
            <div className={`container container_long ${styles.products__content}`}>
                <div className={styles.products__submenu}>
                    {!!activePoint && (
                        <div className={styles.products__clear} onClick={() => setActivePoint(null)}>
                            <img src="/back.svg" alt="back to list" className={styles.products__clearIco} />
                            <GoldText>{t('See all Products')}</GoldText>
                        </div>
                    )}

                    {bottles?.map((bottle: any) => (
                        <React.Fragment key={bottle.id}>
                            <CustomLink
                                title={t(bottle.title)}
                                to="null"
                                onClickHandler={() => setActivePoint(bottle)}
                                isBlack={true}
                            />

                            {activePoint?.id === bottle.id && (
                                <div className={styles.products__liList}>
                                    {bottle.benefits.map((benefit: string, i: number) => (
                                        <div className={styles.products__li} key={i}>
                                            <GoldText>{t(benefit)}</GoldText>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>

                <div className={`${styles.product} ${!!activePoint ? styles.showProduct : styles.hideProduct}`}>
                    {!!activePoint && (
                        <div className={styles.product__content}>
                            <div className={styles.product__picture}>
                                <MoveParallax
                                    xStyles={[
                                        {
                                            position: 'absolute',
                                            inset: '2.4rem',
                                            height: 'calc(100% - 4.8rem)',
                                            width: 'calc(100% - 4.8rem)',
                                        },
                                        {
                                            position: 'absolute',
                                            disply: 'flex',
                                            alignItems: 'center',
                                            jusstifyContent: 'center',
                                            inset: '2.4rem',
                                        },
                                    ]}
                                    config={[
                                        null,
                                        {
                                            xFactor: 0.275,
                                            yFactor: 0,
                                            springSettings: {
                                                stiffness: 60,
                                                damping: 30,
                                            },
                                        },
                                    ]}>
                                    <img
                                        src={activePoint.background_img}
                                        alt={t(activePoint.title)}
                                        className={styles.product__bg}
                                    />
                                    <img
                                        src={activePoint.img}
                                        alt={t(activePoint.title)}
                                        className={styles.product__img}
                                    />
                                </MoveParallax>

                                <img
                                    src={activePoint.img_of_detail}
                                    alt={t(activePoint.title)}
                                    className={styles.product__logo}
                                />
                            </div>

                            <div className={styles.product__data}>
                                <div className={styles.product__topData}>
                                    <h4 dangerouslySetInnerHTML={{ __html: t(activePoint.description) }}></h4>
                                </div>

                                <div className={styles.product__bottomData}>
                                    {activePoint.properties.map((property: any, index: number) => (
                                        <div key={index} className={styles.product__lineData}>
                                            <p
                                                className={`${styles.product__textData} ${styles.product__textData_label}`}>
                                                {property?.title}
                                            </p>
                                            <p className={styles.product__textData}>{property?.value}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.products__bottleWrapper}>
                    {bottles?.map((bottle: any) => (
                        <Left key={bottle.id} subsyles={{ height: '100%', width: 'fit-content' }}>
                            <div
                                className={`${styles.products__bottle} ${!!activePoint ? styles.products__bottle_hide : styles.products__bottle_show}`}
                                style={{ backgroundColor: bottle.color }}
                                onClick={() => setActivePoint(bottle)}
                                onMouseEnter={() => setHoverBottle(bottle.id)}
                                onMouseLeave={() => setHoverBottle(null)}>
                                <img
                                    src={bottle.preview_img}
                                    alt={t(bottle.title)}
                                    className={`${styles.products__bottlePic} ${!!hoverBottle && hoverBottle !== bottle.id ? styles.products__bottlePic_gray : ''}`}
                                />
                            </div>
                        </Left>
                    ))}
                </div>
            </div>
        </div>
    )
})

export default Products
