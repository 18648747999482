/**
 * external libs
 */
import React, { memo, useCallback, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, SubMenuLink } from '../ui'
import Portal from '../portal/Portal'
import Logo from '../../assets/svg/slide_logo.svg'
/**
 * utils
 */
import { SLIDES } from './../horizontal/Horizontal'
/**
 * styles
 */
import styles from './header.module.scss'
import { GlobalContentContext } from '../../App'

type Props = {
    slide: number
    slideRefs: React.RefObject<HTMLDivElement[]>
    scrollLock: React.RefObject<boolean>
}

const Header: React.FC<Props> = memo(({ slide, slideRefs, scrollLock }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [open, setOpen] = useState(false)

    const { homeContent } = useContext(GlobalContentContext)
    const cocktails = homeContent?.data?.cocktail_groups

    const menuCloseHandler = useCallback(
        (idx: number) => {
            if (slideRefs.current?.[idx]) {
                //@ts-ignore
                scrollLock.current = true
                slideRefs.current?.[idx]?.scrollIntoView({ behavior: 'smooth' })

                const backToScroll = () => {
                    // console.log("scrollLock.current ZERO", slideRefs.current?.[idx]?.getBoundingClientRect().x)
                    if (
                        Number(slideRefs.current?.[idx]?.getBoundingClientRect().x) > -1 &&
                        Number(slideRefs.current?.[idx]?.getBoundingClientRect().x) < 1
                    ) {
                        //@ts-ignore
                        scrollLock.current = false
                    } else {
                        setTimeout(backToScroll, 50)
                    }
                }

                backToScroll()
            }
            setOpen(false)
        },
        [setOpen]
    )

    return (
        <header className={styles.header}>
            <div className="container">
                <div className={styles.header__container}>
                    {slide !== 0 && (
                        <img src={Logo} className={styles.header__logo} onClick={() => menuCloseHandler(0)} />
                    )}

                    <div className={styles.header__dataWrapper}>
                        <div className={styles.header__data} onClick={() => setOpen((pre) => !pre)}>
                            <div className={`${styles.burger} ${open ? styles.active : ''}`}>
                                <div className={styles.burger__line} />
                                <div className={styles.burger__line} />
                                <div className={styles.burger__line} />
                            </div>

                            <GoldText>{!open ? t('menu') : t('close')}</GoldText>
                        </div>
                    </div>
                </div>
            </div>

            <Portal open={open} onClose={() => setOpen(false)}>
                <nav className={`container ${styles.header__nav}`}>
                    {SLIDES.map((title, idx) => (
                        <SubMenuLink
                            key={title}
                            title={t(title)}
                            onClickHandler={() =>
                                menuCloseHandler(idx === SLIDES.length - 1 ? idx + cocktails?.length - 1 : idx)
                            }
                            to={idx === slide ? location.pathname : ''}
                        />
                    ))}
                </nav>
            </Portal>
        </header>
    )
})

export default Header
