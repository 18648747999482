/**
 * external libs
 */
import React, { createContext, useState, useRef, useCallback, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { getHomeContent, getPrivacyContent, getTermOfUseContent } from './utils/api'
/**
 * components
 */
import Router from './routers/Router'
import Alerts from './common-components/alerts/Alerts'
import Loader from './common-components/loader/Loader'
import Cookie from './common-components/cookie/Cookie'
/**
 * utils
 */
import i18n from './utils/i18n/i18n'
/**
 * types
 */
import { GlobalContextType, AlertType, AlertContentType, GlobalContentContextType } from './types'
/**
 * styles
 */
import './global.scss'

export const GlobalContext = createContext<GlobalContextType>({
    addAlert: null,
    setLoaderCount: null,
})

export const GlobalContentContext = createContext<GlobalContentContextType>({
    privacyContent: null,
    termOfUseContent: null,
    homeContent: null,
    handleSetLanguage: () => {},
})

function App() {
    const [alerts, setAlerts] = useState<AlertType[]>([])
    const [loaderCount, setLoaderCount] = useState<number>(0)
    const alertId = useRef<number>(0)
    const [language, setLanguage] = useState<string>(localStorage.getItem('language') || 'ru')
    const [isAcceptedCookie, setIsAcceptedCookie] = useState(!!localStorage.getItem('acceptedCookie'))

    // Объединенное состояние для всех данных
    const [allContent, setAllContent] = useState({
        privacyContent: null,
        termOfUseContent: null,
        homeContent: null,
    })

    const addAlert = useCallback(
        (alert: AlertContentType) => {
            alertId.current = alertId.current + 1
            setAlerts((pre) => [...pre, { ...alert, id: alertId.current }])
        },
        [alertId]
    )

    const handleSetLanguage = useCallback(
        (lang: string) => {
            if (lang !== language) {
                console.log('Changing language to:', lang)
                i18n?.changeLanguage(lang)
                localStorage.setItem('language', lang)
                setLanguage(lang)
            }
        },
        [language]
    )

    useEffect(() => {
        console.log('Fetching content due to language change:', language)

        let isMounted = true // Флаг для проверки, что компонент смонтирован

        const fetchAllContent = async () => {
            try {
                // Запрашиваем все данные одновременно
                const [privacy, terms, home] = await Promise.all([
                    getPrivacyContent(language),
                    getTermOfUseContent(language),
                    getHomeContent(language),
                ])

                if (isMounted) {
                    console.log('Setting content:', privacy, terms, home, language)

                    // Обновляем все состояния вместе
                    setAllContent({
                        privacyContent: privacy,
                        termOfUseContent: terms,
                        homeContent: home,
                    })
                }
            } catch (error) {
                console.error('Failed to fetch content:', error)
            }
        }

        fetchAllContent()

        return () => {
            isMounted = false // Отмена выполнения, если компонент будет размонтирован
        }
    }, [language])

    return (
        <I18nextProvider i18n={i18n}>
            <GlobalContext.Provider value={{ addAlert, setLoaderCount }}>
                <GlobalContentContext.Provider value={{ ...allContent, handleSetLanguage }}>
                    <Alerts alerts={alerts} setAlerts={setAlerts} />
                    {loaderCount > 0 && <Loader />}

                    {!isAcceptedCookie && <Cookie setIsAcceptedCookie={setIsAcceptedCookie} />}

                    <HelmetProvider>
                        <Router />
                    </HelmetProvider>
                </GlobalContentContext.Provider>
            </GlobalContext.Provider>
        </I18nextProvider>
    )
}

export default React.memo(App)
